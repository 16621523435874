/*
* class AppFunctions
*/

const AppFunctions = function () {

    /*
    * Env variables
    */

    // var slides = []

    /*
    * front
    */

    let textslideshow = function ( nodeid, duration ) {

        duration = duration || 4000;

        let slide = document.getElementById(nodeid);
        let gifholder = document.getElementById(slide.dataset.gifHolder);
        let minh = 0;
        let minw = 0;

        [].forEach.call(slide.children, function(slide) {

            let strs = slide.innerHTML.split('|');
            let html = '';

            [].forEach.call(strs, function(str) {
                html = html + '<span><span>'+str+'</span></span>';
            });

            slide.innerHTML = html;

            if ( minh < slide.offsetHeight ) minh = slide.offsetHeight;
            if ( minw < slide.offsetWidth ) minw = slide.offsetWidth;
        });

        function setGif( slide ){
            let uri = slide.dataset.gif;
            gifholder.classList.add('on-switch');

            setTimeout(function(){
                gifholder.style.backgroundImage = 'url( ' + slide.dataset.gif + ' )';
            },500);
            setTimeout(function(){
                gifholder.classList.remove('on-switch')
            },1000)
        }

        function goFirst( current ){

            let newitem = slide.children[0];
            newitem.classList.add('active');

            current.classList.remove('active');

            return newitem;
        }

        function nexttext(){

            let current = slide.querySelector('.active');
            let newitem = current.nextElementSibling;

            let old = slide.querySelector('.old-active');
            if (old) old.classList.remove('old-active');

            if ( !newitem ) newitem = goFirst(current);

            current.classList.add('old-active');
            current.classList.remove('active');
            newitem.classList.add('active');

            slide.style.minHeight = newitem.offsetHeight+'px';
            slide.style.minWidth = newitem.offsetWidth+'px';
            if ( newitem.dataset.gif && gifholder ) setGif(newitem);
        }

        setInterval(function(){
            nexttext()
        }, duration);

        slide.style.minHeight = minh+'px';
        slide.style.minWidth = minw+'px';

        slide.classList.add('initiated');
        let startitem = slide.children[0];
        startitem.classList.add('active');
        if ( startitem.dataset.gif && gifholder ) setGif(startitem);
    };

    let drawpics = function() {

        let sizeh = 50;
        let sizew = 100;
        let links = document.querySelectorAll('.canvaslink');
        let mouseX;
        let mouseY;
        let particles;
        let ctx;
        let c;
        let trash = [];

        links.forEach( link => {
            link.addEventListener('mouseover', () => {

                if( !link.classList.contains('animated') )
                    link.classList.add('animated');
                else
                    return;

                let id = link.getAttribute('href') + '_' + Date.now();
                let linkpos = link.getBoundingClientRect();
                link.setAttribute('data-id', id);



                c = document.createElement('canvas');
                c.id = id;
                c.style.width = sizew + 'px';
                c.style.height = sizeh + 'px';
                c.style.top = (linkpos.y + window.scrollY) - (sizeh / 2) + (link.offsetHeight / 2)+ 'px';
                c.style.left = (linkpos.x + window.scrollX) - (sizew / 2) + (link.offsetWidth / 2) + 'px';
                c.style.position = 'absolute';

                document.body.append(c);

                ctx = c.getContext('2d');
                let btn = link;

                mouseX = c.width / 2;
                mouseY = c.height / 2;

                let txtPosition = 0;
                particles = [];

                // btn.addEventListener('mouseup', function(e){
                //     mouseX = e.clientX;
                //     mouseY = e.clientY;
                // });

                createParticles();
                // changeText();
                trash[id] = c;

                let anim = draw();

                setTimeout(function(){
                    trash[id].remove();
                    delete trash[id];
                    link.classList.remove('animated');
                    cancelAnimationFrame(anim);
                }, 2000);


            })
        });

        function draw(){

            drawBg();
            incParticles();
            drawParticles();
            return window.requestAnimationFrame(draw);
        }

        function drawBg(){
            ctx.rect(0, 0, c.width, c.height);
            ctx.fillStyle = "rgb(255, 255, 255)";
            ctx.fill();
        }

        function drawParticles(){
            for(let i = 0; i < particles.length; i++){
                ctx.beginPath();
                ctx.arc(particles[i].x,
                    particles[i].y,
                    particles[i].size,
                    0,
                    Math.PI * 2);
                ctx.fillStyle = particles[i].color;
                ctx.closePath();
                ctx.fill();
            }
        }

        function incParticles(){
            for(let i = 0; i < particles.length; i++){
                particles[i].x += particles[i].velX;
                particles[i].y += particles[i].velY;

                particles[i].size = Math.max(0, (particles[i].size - .05));

                if(particles[i].size === 0){
                    particles.splice(i, 1);
                }
            }
        }

        function createParticles(){
            for(let i = 0; i < 30; i++){
                particles.push({
                    x: mouseX,
                    y: mouseY,
                    size: parseInt(Math.random() * 10),
                    color: 'rgba(' + ranRgb() + ')',
                    velX: ranVel(),
                    velY: ranVel()
                });
            }
        }

        function ranRgb(){
            let colors = [
                '255, 90, 91, 1',
                '255, 90, 91, .85',
                '255, 90, 91, 1',
                '255, 90, 91, .25',
                '255, 90, 91, .1'
            ];

            let i = parseInt(Math.random() * 10);

            return colors[i];
        }

        function ranVel(){
            let vel = 0;

            if(Math.random() < 0.5){
                vel = Math.abs(Math.random());
            } else {
                vel = -Math.abs(Math.random());
            }

            return vel;
        }
    };

    let controlResp = function () {

        // Exemple actions pour responsive
        let togglemenu = document.getElementById('burger');
        let menublock  = document.getElementById('modal-menu');
        let links = menublock.querySelectorAll('a[href^="#"]');

        function closemenu() {
            togglemenu.classList.add('closing');
            menublock.classList.add('closing');
            setTimeout(function(){
                togglemenu.classList.remove('open');
                menublock.classList.remove('open');

                document.body.classList.remove('overled')
            },750);
        }

        function openmenu() {
            togglemenu.classList.remove('closing');
            menublock.classList.remove('closing');
            togglemenu.classList.add('open');
            menublock.classList.add('open');
            document.body.classList.add('overled')
        }

        togglemenu.addEventListener('touchstart', function(event){
            event.preventDefault();
            event.stopPropagation();
            if( togglemenu.classList.contains('open') ){
                closemenu()
            }
            else{
                openmenu()
            }
        });

        [].forEach.call(links, function(link){
            link.addEventListener('touchstart', function(event){
                event.preventDefault();
                event.stopPropagation();
                closemenu();
                AppTools.scrollActions.scrollTo(link.getAttribute('href'))
            })
        })
    };

    return {

        /*
        * Main class initializer
        */

        init: function () {

            AppFunctions.initComponents();

            while (pagefunctions.length) {
                pagefunctions.shift().call();
            }

            if (typeof launchFunction == 'function')
                launchFunction()
        },

        /*
        * Initializes components
        */

        initComponents: function () {

            drawpics();
            controlResp()
            // controlForm()
            // controlSlides()
            // loadOverlay({'overlay':'contactform'})

            // $(window).resize(function() {
            // controlResp()
            // })
        },

        initTextslide: function ( nodeid, duration ) {
            textslideshow( nodeid, duration )
        }
    };

}();

(function() {

    // AppFunctions.init();

})();
